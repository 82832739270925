﻿import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'; // enables clipboard, Enter, ShiftEnter, typing and undo support
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
import FontSize from '@ckeditor/ckeditor5-font/src/font';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import StrikeThrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote'; // Heading dropdown not working then importing BlockQuote
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';

import UploadAdapter from '@ckpro/ckeditor5-upload-adapter';
import Syntaxer from 'ckeditor5-syntaxer';
import Bookmark from '@ckpro/ckeditor5-bookmark';
import Iframe from '@ckpro/ckeditor5-iframe';

//import Screenshot from './customplugins/screenshot/src/screenshot';
import Screenshot from '@ckpro/ckeditor5-screenshot';
//import Syntaxer from './customplugins/syntaxer/src/syntaxer';
//import Bookmark from './customplugins/bookmark/src/bookmark';
import Popover from '@ckpro/ckeditor5-popover';

export default class ClassicEditor extends ClassicEditorBase { }

ClassicEditor.builtinPlugins = [
    Essentials, //necessary, eg. Enter will not work within this plugin
    Bold,
    Italic,
    Heading,
    FontFamily,
    FontSize,
    FontBackgroundColor,
    FontColor,
    Underline,
    StrikeThrough,
    Subscript,
    Superscript,
    Alignment,
    BlockQuote,
    Highlight,
    List,
    Link,
    PasteFromOffice, // does not have any toolbar buttons, instead it makes the ckeditor able to handle paste from office
    Syntaxer,
    Bookmark,
    Screenshot,
    Popover,
    Iframe,
    MediaEmbed,
    Image, // necessary
    ImageCaption, // adds a caption option to images
    ImageToolbar, // adds a style toolbar for image positioning 
    ImageStyle, // adds the styles for the image toolbar for image positioning
    ImageUpload, // holds the image upload toolbar button
    UploadAdapter,
    Table,
    TableToolbar
];

ClassicEditor.defaultConfig = {
    syntaxer: {
        languages: ['c#', 'php', 'sql', 'javascript', 'json', 'xml', 'html', 'css'], // java, markdown
        element: 'pre'
    },
    fontColor: {
        columns: 5,
        documentColors: 10,
        colors: [ // hsl : Hue, Saturation, Light
            {
                color: 'hsl(0, 0%, 0%)',
                label: 'Black'
            },
            {
                color: 'hsl(0, 0%, 30%)',
                label: 'Dim grey'
            },
            {
                color: 'hsl(0, 0%, 60%)',
                label: 'Grey'
            },
            {
                color: 'hsl(0, 0%, 90%)',
                label: 'Light grey'
            },
            {
                color: 'hsl(0, 0%, 100%)',
                label: 'White',
                hasBorder: true
            },
            {
                color: 'hsl(0,75%,60%)',
                label: 'Light red'
            },
            {
                color: 'rgb(206,0,0)',
                Label: 'Red'
            },
            {
                color: 'hsl(30,75%,60%)',
                label: 'orange'
            },
            {
                color: 'hsl(60,75%,60%)',
                label: 'Yellow'
            },
            {
                color: 'hsl(90,75%,60%)',
                label: 'Light green'
            },
            {
                color: 'hsl(120,75%,60%)',
                label: 'Green'
            },
            {
                color: 'hsl(96, 63%,43%)',
                label: 'Dark green'
            },
            {
                color: 'hsl(150,75%,60%)',
                label: 'Aquamarine'
            },
            {
                color: 'hsl(180,75%,60%)',
                label: 'Turquoise'
            },
            {
                color: 'hsl(210,75%,60%)',
                label: 'Light blue'
            },
            {
                color: 'rgb(0,0,255)',
                label: 'Blue'
            },
            {
                color: 'hsl(240,75%,60%)',
                label: 'Blue purple'
            },
            {
                color: 'hsl(270,75%,60%)',
                label: 'Light purple'
            },
            {
                color: 'rgb(114,4,246)',
                label: 'Purple'
            },
            {
                color: 'rgb(255,0,255)',
                label: 'Pink'
            }

            // ...
        ]
    },
}